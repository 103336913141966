<template>
  <div>
    <slot name="header" :contactDialogs="dialogs" :toggleDialog="toggleDialog"></slot>
    <v-dialog v-model="dialogs.image" max-width="400">
      <v-card class="mx-auto">
        <v-card-title>
          Add image
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.image = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-slide-y-transition>
            <v-alert text type="error" v-if="errors.gallery">
              {{ errors.gallery }}
            </v-alert>
          </v-slide-y-transition>

          <v-progress-linear
            indeterminate
            color="primary"
            v-if="loading.gallery"
          ></v-progress-linear>

          <div class="text-center" v-if="imageUrl">
            <v-avatar size="200">
              <v-img :src="imageUrl"></v-img>
            </v-avatar>
            <div>
              <v-btn
                color="primary"
                text
                class="v-btn--active mr-1 text-none"
                @click="selectImage()"
                >Select</v-btn
              >
              <v-btn color="secondary" text class="text-none" @click="changeImage()">Change</v-btn>
            </div>
          </div>
          <v-text-field
            :outlined="outlined"
            v-else
            label="Gallery Image ID"
            v-model="form.imageId"
            prefix="#"
            @input="fetchImage()"
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="text-center" v-show="showField('photo')">
      <div>
        <v-avatar color="primary lighten-4" size="80">
          <v-img v-if="form.imageUrl" :src="form.imageUrl"></v-img>
          <v-icon color="primary" size="40" v-else>mdi-account</v-icon>
        </v-avatar>
        <div class="mt-1">
          <v-btn text @click="dialogs.image = true" color="primary" class="text-none mb-1">{{
            form.imageUrl ? 'Change photo' : 'Add photo'
          }}</v-btn>
        </div>
      </div>
    </div>
    <v-row v-show="showField('name')">
      <v-col>
        <v-text-field
          :outlined="outlined"
          label="First name"
          v-bind="attrs"
          v-model="form.firstName"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-bind="attrs"
          :outlined="outlined"
          label="Last name"
          v-model="form.lastName"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-text-field
      :rules="emailRule"
      v-show="showField('email')"
      v-bind="attrs"
      :outlined="outlined"
      label="Email"
      v-model="form.email"
      :append-icon="form.email ? 'mdi-email' : ''"
      @click:append="dialogs.email = true"
    ></v-text-field>
    <div v-show="showField('address')" class="my-2">
      <!-- <v-divider class="my-2"></v-divider> -->
      <div v-for="(address, index) in form.address" :key="address.id">
        <v-hover v-slot="{ hover }">
          <div>
            <div class="text-capitalize">
              <div class="d-flex align-baseline">
                <BaseConfirmAction
                  v-slot="{ confirm, cancel, on }"
                  :class="form.address.length <= 1 ? 'invisible' : ''"
                >
                  <div v-if="!readonly">
                    <v-icon
                      :style="{ visibility: hover ? '' : 'hidden' }"
                      v-if="form.address.length > 1 && !confirm"
                      v-on="on"
                      color="error"
                    >
                      mdi-minus-circle
                    </v-icon>
                    <v-slide-x-transition hide-on-leave>
                      <div v-show="confirm">
                        <v-btn
                          @click="removeAddress(address.id)"
                          small
                          color="error"
                          class="text-capitalize"
                          >Remove</v-btn
                        >
                        <v-btn @click="cancel" class="text-capitalize ml-1" text small color="grey"
                          >Cancel</v-btn
                        >
                      </div>
                    </v-slide-x-transition>
                  </div>
                </BaseConfirmAction>
                <v-menu
                  :close-on-content-click="false"
                  v-model="dialogs.menuAddress[index]"
                  :disabled="readonly"
                >
                  <template #activator="{ on }">
                    <div
                      v-on="on"
                      style="min-width: 100px"
                      class="primary--text cursor-pointer ml-2 text-left mb-3"
                    >
                      {{ address.label }} <v-icon v-if="!readonly">mdi-chevron-down</v-icon>
                    </div>
                  </template>
                  <template>
                    <v-list nav>
                      <v-list-item-group :value="address.label" color="primary">
                        <v-list-item
                          v-for="(label, labelIndex) in addressLabels"
                          :value="label"
                          :key="labelIndex"
                          @click="selectAddressLabel(label, index)"
                        >
                          <v-list-item-content>
                            {{ label }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-btn block class="mt-2 text-capitalize" text @click="showAddCustomLabel()"
                        >Add custom label</v-btn
                      >
                      <v-container>
                        <v-text-field
                          autofocus
                          @keyup.enter="addCustomLabel('address')"
                          @blur="addCustomLabel('address')"
                          ref="customLabel"
                          v-if="showCustomLabel"
                          v-model="customLabel"
                        ></v-text-field>
                      </v-container>
                    </v-list>
                  </template>
                </v-menu>
              </div>
            </div>
            <div class="ml-0 ml-sm-0 mb-0 pb-0">
              <v-textarea
                v-bind="attrs"
                :outlined="outlined"
                label="Street"
                v-model="address.street"
                auto-grow
                rows="1"
              ></v-textarea>
              <v-row>
                <v-col cols="5">
                  <v-textarea
                    :outlined="outlined"
                    label="City"
                    v-bind="attrs"
                    v-model="address.city"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="3" class="mb-0 pb-0">
                  <v-textarea
                    :outlined="outlined"
                    label="State"
                    class="mr-1"
                    v-bind="attrs"
                    v-model="address.state"
                    @input="onStateInput($event, index)"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="4" class="mb-0 pb-0">
                  <v-textarea
                    :outlined="outlined"
                    label="Zip"
                    v-bind="attrs"
                    @input="onZipInput($event, index)"
                    v-model="address.zip"
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-text-field
                v-show="gps"
                label="GPS"
                v-model="address.gps"
                v-bind="$attrs"
              ></v-text-field>
            </div>
          </div>
        </v-hover>
      </div>
      <v-btn @click="addAddress()" text class="text-none" color="success" v-if="!readonly"
        ><v-icon color="success">mdi-plus-circle</v-icon> add address</v-btn
      >
      <!-- <v-divider class="my-2"></v-divider> -->
    </div>
    <div v-show="showField('phone')">
      <div>
        <div v-for="(item, i) in form.phone" :key="item.id">
          <v-hover v-slot="{ hover }">
            <div>
              <div class="d-flex align-baseline">
                <BaseConfirmAction
                  v-slot="{ click, confirm, cancel }"
                  :class="form.phone.length <= 1"
                  v-if="!readonly"
                >
                  <div :style="{ visibility: hover ? '' : 'hidden' }">
                    <v-icon color="error" v-if="!confirm && form.phone.length > 1" @click="click"
                      >mdi-minus-circle</v-icon
                    >
                  </div>
                  <div>
                    <v-btn
                      v-if="confirm"
                      small
                      text
                      color="error"
                      class="text-none v-btn--active"
                      @click="form.phone.splice(i, 1)"
                      >Remove</v-btn
                    >
                    <v-btn small v-if="confirm" text color="grey" class="text-none" @click="cancel"
                      >Cancel</v-btn
                    >
                  </div>
                </BaseConfirmAction>
                <v-menu
                  :close-on-content-click="false"
                  v-model="dialogs.menu[i]"
                  :disabled="readonly"
                  :attach="attach"
                  min-width="200"
                >
                  <template #activator="{ on }">
                    <div
                      v-on="on"
                      style="min-width: 100px"
                      class="primary--text cursor-pointer ml-2 text-left"
                      text
                    >
                      {{ form.phone[i].label }} <v-icon v-show="!readonly">mdi-chevron-down</v-icon>
                    </div>
                  </template>
                  <template>
                    <v-list nav>
                      <v-list-item-group :value="item.label" color="primary">
                        <v-list-item
                          v-for="(label, labelIndex) in contactLabels"
                          :key="labelIndex"
                          :value="label"
                          @click="selectLabel(label, i)"
                        >
                          <v-list-item-content>
                            {{ label }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      <v-btn block class="mt-2 text-capitalize" text @click="showAddCustomLabel()"
                        >Add custom label</v-btn
                      >
                      <v-container>
                        <v-text-field
                          autofocus
                          @keyup.enter="addCustomLabel"
                          @blur="addCustomLabel"
                          ref="customLabel"
                          v-if="showCustomLabel"
                          v-model="customLabel"
                        ></v-text-field>
                      </v-container>
                    </v-list>
                  </template>
                </v-menu>
                <div class="d-flex align-center">
                  <v-text-field
                    type="tel"
                    :maxlength="formatPhoneNumber ? 14 : 10"
                    v-bind="attrs"
                    :value="form.phone[i].number"
                    @input="formatPhone($event, i)"
                    :outlined="outlined"
                    placeholder="Phone"
                  />
                </div>
              </div>
            </div>
          </v-hover>
        </div>
        <v-btn @click="addPhone()" text color="success" class="text-none mb-3" v-if="!readonly">
          <v-icon color="success">mdi-plus-circle</v-icon> add phone</v-btn
        >
      </div>
      <!-- <v-divider class="my-2"></v-divider> -->
    </div>
    <v-text-field
      v-show="showField('company')"
      :outlined="outlined"
      label="Company"
      v-bind="attrs"
      v-model="form.company"
    ></v-text-field>
    <v-combobox
      v-show="showField('tags')"
      v-model="form.tags"
      :outlined="outlined"
      :search-input.sync="search"
      hide-selected
      v-bind="attrs"
      label="Tags"
      multiple
      persistent-hint
      small-chips
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No results matching "<strong>{{ search }}</strong
              >". Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <v-textarea
      v-show="showField('bio')"
      :outlined="outlined"
      rows="2"
      v-bind="attrs"
      auto-grow
      v-model="form.bio"
      label="Bio"
    ></v-textarea>
    <v-textarea
      v-show="showField('note')"
      v-bind="attrs"
      :outlined="outlined"
      rows="2"
      auto-grow
      v-model="form.note"
      label="Note"
    ></v-textarea>
  </div>
</template>

<script>
import { galleryRef } from '../services/firestore';
const DEFAULT_FIELDS = [
  'photo',
  'name',
  'email',
  'address',
  'phone',
  'company',
  'tags',
  'bio',
  'note'
];
import Address from '../services/address';
import Contact from '../services/contact';
import { complement, randomNumber } from '../utils';
import Phone from '../services/phone';
export default {
  props: {
    gps: {
      type: Boolean,
      default: false
    },
    noOutline: {
      type: Boolean,
      default: false
    },
    fields: {
      type: [Array],
      default: () => {
        return [];
      }
    },
    exclude: {
      type: Array,
      default: () => {
        return [];
      }
    },
    outlined: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {
        return null;
      }
    },
    country: {
      type: String,
      default: 'US'
    },
    formatPhoneNumber: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      emailRule: [
        v => {
          if (v) {
            return /.+@.+\..+/.test(v) || 'Enter a valid email';
          }
          return true;
        }
      ],
      imageUrl: '',
      attach: '',
      loading: {
        gallery: false
      },
      dialogs: {
        image: false,
        menu: {},
        menuAddress: {},
        email: false
      },
      errors: {
        gallery: ''
      },
      search: '',
      form: Contact.validateData(),
      contactLabels: ['phone', 'mobile', 'home', 'work', 'other'],
      addressLabels: ['home', 'work', 'school', 'other'],
      showCustomLabel: false,
      customLabel: '',
      timeout: {
        zip: null,
        image: null
      },
      showFields: false
    };
  },
  computed: {
    attrs() {
      return {
        class: {
          'input-no-outline': this.noOutline
        }
      };
    }
  },
  methods: {
    formatPhone(phone, index) {
      if (this.formatPhoneNumber) {
        const p = new Phone({ phone });
        this.form.phone[index].number = p.format();
      } else {
        this.form.phone[index].number = phone;
      }
    },
    toggleDialog(key) {
      this.dialogs[key] = !this.dialogs[key];
    },
    removeAddress(id) {
      const index = this.form.address.findIndex(i => i.id === id);
      if (this.form.address.length > 1) {
        this.form.address.splice(index, 1);
      }
    },
    onStateInput(value, i) {
      this.form.address[i].state = String(value).toUpperCase();
    },
    visibleFields(readonly = false) {
      let fields = DEFAULT_FIELDS;
      if (this.fields && this.fields.length) {
        fields = this.fields;
      }
      if (this.exclude && this.exclude.length) {
        fields = fields.filter(f => {
          return !this.exclude.includes(f);
        });
      }
      if (readonly) {
        const c = new Contact();
        c.setData({ ...this.form });
        fields = fields.filter(f => {
          return !c.isFieldEmpty(f);
        });
      }
      return fields;
    },
    showField(field) {
      return this.visibleFields(this.readonly).includes(field);
    },
    getData() {
      const data = { ...this.form };
      Object.keys(data).forEach(key => {
        if (data[key] === undefined) {
          data[key] = null;
        }
      });
      return data;
    },
    sendEmail() {
      window.open(`mailto:${this.form.email}`, '_blank').focus();
    },
    addAddress() {
      const label = complement(
        this.addressLabels,
        this.form.address.map(i => i.label)
      )[0];
      this.form.address.push({
        street: '',
        city: '',
        state: '',
        zip: '',
        label: label || 'other',
        id: randomNumber()
      });
    },
    onAddressInput() {
      const addAddress = this.form.address.every(
        address => address.street || address.city || address.state || address.zip
      );
      if (addAddress) {
        this.addAddress();
      }
    },
    onZipInput(value, i) {
      const zip = String(value).replace(/[^0-9]/g, '');
      setTimeout(() => {
        this.form.address[i].zip = zip;
      }, 1);
      if (this.timeout.zip) {
        clearTimeout(this.timeout.zip);
        this.timeout.zip = null;
      }
      this.timeout.zip = setTimeout(() => {
        const address = new Address();
        const data = address.get({ zip });
        if (data) {
          this.form.address[i].state = data.state;
          this.form.address[i].city = data.townCity;
        }
      }, 100);
    },
    changeImage() {
      this.form.imageId = '';
      this.imageUrl = '';
    },
    selectImage() {
      this.form.imageUrl = this.imageUrl;
      this.imageUrl = '';
      this.dialogs.image = false;
    },
    fetchImage() {
      if (this.timeout) {
        clearTimeout(this.timeout.image);
        this.timeout.image = null;
      }
      this.timeout.image = setTimeout(() => {
        this.loading.gallery = true;
        galleryRef
          .where('images', 'array-contains', this.form.imageId)
          .get()
          .then(res => {
            if (res.size) {
              const images = res.docs[0].data().files || [];
              const img = images.filter(({ id }) => id === this.form.imageId)[0];
              if (!img) {
                this.errors.gallery = 'Image not found';
              } else {
                this.imageUrl = img.url;
              }
            } else {
              this.errors.gallery = 'Image not found';
            }
          })
          .catch(err => {
            this.errors.gallery = err.message;
          })
          .finally(() => {
            this.loading.gallery = false;
          });
      }, 1000);
    },
    selectLabel(label, index) {
      this.form.phone[index].label = label;
      this.dialogs.menu[index] = false;
    },
    selectAddressLabel(label, index) {
      this.form.address[index].label = label;
      this.dialogs.menuAddress[index] = false;
    },
    addPhone() {
      const hasLabel = (data, label) => {
        let res = false;
        data.forEach(item => {
          if (label === item.label) {
            res = true;
          }
        });
        return res;
      };
      const labelsRemaining = this.contactLabels.filter(i => !hasLabel(this.form.phone, i));
      this.form.phone.push({
        label: labelsRemaining[0] || 'phone',
        number: '',
        id: randomNumber()
      });
    },
    onInputPhone() {
      const phoneNumbers = this.form.phone.every(i => !!i.number);
      if (phoneNumbers) {
        this.addPhone();
      }
    },
    addCustomLabel(type) {
      if (type === 'address') {
        this.addressLabels.push(this.customLabel);
        this.customLabel = '';
        this.showCustomLabel = false;
      } else {
        this.contactLabels.push(this.customLabel);
        this.customLabel = '';
        this.showCustomLabel = false;
      }
    },
    showAddCustomLabel() {
      this.customLabel = '';
      this.showCustomLabel = true;
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.form = Contact.validateData(value, this.formatPhoneNumber);
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" >
</style>
