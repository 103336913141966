export default class Address {
    constructor() {
        this.addresses = [
            { zip: '02666', state: 'MA', townCity: 'Truro' },
            { zip: '02652', state: 'MA', townCity: 'Truro' },
            { zip: '02667', state: 'MA', townCity: 'Wellfleet' },
            { zip: '02663', state: 'MA', townCity: 'Wellfleet' },
            { zip: '02445', state: 'MA', townCity: 'Brookline' },
            { zip: '02446', state: 'MA', townCity: 'Brookline' },
            { zip: '02474', state: 'MA', townCity: 'Arlington' },
            { zip: '02493', state: 'MA', townCity: 'Weston' },
            { zip: '02657', state: 'MA', townCity: 'Provincetown' },
            { zip: '02651', state: 'MA', townCity: 'Eastham' },
            { zip: '02642', state: 'MA', townCity: 'Eastham' },
            { zip: '02653', state: 'MA', townCity: 'Orleans' },
            { zip: '02631', state: 'MA', townCity: 'Brewster' },
            { zip: '02061', state: 'MA', townCity: 'Norwell' },
            { zip: '02066', state: 'MA', townCity: 'Scituate' },
            { zip: '02116', state: 'MA', townCity: 'Boston' },
            { zip: '01845', state: 'MA', townCity: 'North Andover' }
        ]
    }
    get({ zip } = {}) {
        let res = null
        if (zip) {
            res = this.addresses.filter((address) => {
                return String(zip) === String(address.zip)
            })[0]
        }
        return res;
    }
}